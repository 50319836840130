// src/firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBy-1u5PxSpMq41ljzhfXTboZMME-Hz1SM",
  authDomain: "eibiz-4d470.firebaseapp.com",
  projectId: "eibiz-4d470",
  storageBucket: "eibiz-4d470.appspot.com",
  messagingSenderId: "993232253702",
  appId: "1:993232253702:web:b2aeb30ce05455b7bb6487",
  measurementId: "G-NKPTRXEPH4",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken };
