import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo, SignUp, left } from "../../assets/image";

const DirectorSuccessful = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main">
        <div className="main-logo ">
          <img src={Logo} alt="logo" className="w-full h-full object-cover" />
        </div>
        <div className="center-h">
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto items-center justify-center">
              <div className="items-center justify-center flex flex-col mb-4">
                <p className="font-ebGaramond text-2xl font-normal">
                  Welcome to EiBIZ!
                </p>
                <p className="font-ebGaramond text-xl font-normal text-center">
                  We see’s that you are invited to be part of “Company
                  Name”Let’s get you started now.
                </p>
              </div>
              <div className="relative w-fit h-fit">
                <img
                  src={SignUp}
                  alt="logo"
                  className="w-full h-full object-cover mt-2"
                />
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  className="text-theme"
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Proceed
                </button>
                <img src={left} alt="logo" className="w-5 h-5 object-cover" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default DirectorSuccessful;
