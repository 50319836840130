import React from "react";
import { Close } from "../../assets/svg/AllSvg";
import { AddedCompany, Registration, Switch } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";

const AddCompany = ({ setAddCompanyPopup }) => {
  const navigate = useNavigate();

  // const handleNewCompany = () => {
  //   setAddCompanyPopup(false);
  //   navigate("/new-company", { state: { companyType: "Incorporate" } });
  // };

  return (
    <>
      <div className="backdrop-blur-sm bg-black/40 flex items-center justify-center w-full fixed top-0 left-0 right-0 z-[999999] mx-auto h-screen">
        <div className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative">
          <div className="flex justify-center items-center">
            <img src={AddedCompany} alt="logo" className="h-24 w-28" />
            <p className="text-3xl font-ebGaramond font-normal w-full ml-6">
              Let’s Get Started Together!
            </p>
          </div>
          <Close
            className="cursor-pointer absolute top-5 right-5"
            onClick={() => {
              setAddCompanyPopup(false);
              sessionStorage.setItem("isCompanyPopup", false);
            }}
          />
          <div className="w-full flex">
            <button
              className="w-1/2 bg-lightSecondaryShades h-60 m-2 rounded-lg flex justify-center items-center flex-col"
              onClick={() => {
                setAddCompanyPopup(false);
                navigate("/new-company", {
                  state: { companyType: "incorporate" },
                });
              }}
            >
              <img
                src={Registration}
                alt="logo"
                className="object-cover w-6 h-6"
              />
              <p className="text-xl font-ebGaramond font-normal text-secondary">
                Incorporate now
              </p>
            </button>
            <div
              className="w-1/2 bg-lightPrimaryShades cursor-pointer h-60 m-2 rounded-lg flex justify-center items-center flex-col"
              onClick={() => {
                setAddCompanyPopup(false);
                navigate("/new-company", { state: { companyType: "eibiz" } });
              }}
            >
              <img src={Switch} alt="logo" className="object-cover w-6 h-6" />
              <p className="text-xl font-ebGaramond font-normal text-green-600">
                Switch to EI BIZ
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col">
            <p className="text-xl font-ebGaramond font-light">
              Not sure what to do?{" "}
            </p>
            <Link
              to={""}
              className="border-b border-blue-700 text-blue-700 font-normal text-sm font-ebGaramond"
            >
              contact us now!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompany;
