export const modules = {
  toolbar: [["bold", "underline", "link"], [{ undo: "custom-undo" }]],
  // toolbar: {
  //   container: "#toolbar",
  // },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "image",
  "pdf",
  "bullet",
  "indent",
  "link",
];
