import React from "react";
import { successCheckout, left } from "../../assets/image";
import { Link } from "react-router-dom";

const CheckOutSuccess = () => {
  return (
    <div>
      <p className="font-ebGaramond text-base">Thank You!</p>
      <p className="font-ebGaramond text-3xl">
        You had successfully signed up!
      </p>
      <img className="h-60 w-80" src={successCheckout} />
      <p className="border border-gray-400 mt-2 w-80" />
      <p className="font-poppins text-sm mt-1">
        You can now monitor service request here:
      </p>
      <div className="flex items-center">
        <Link
          to="/service-request"
          className="border-b border-blue-700 text-blue-700 font-normal text-xs font-poppins"
        >
          service request
        </Link>
        <img className="h-4 w-4 ml-1" src={left} />
      </div>
    </div>
  );
};
export default CheckOutSuccess;
