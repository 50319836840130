import React, { useEffect, useState } from "react";
import { myCompany } from "../../assets/image";
import "../auth/Auth.css";
import "../dashboard/dashboard.css";
import { Add } from "iconsax-react";
import AddUser from "../modal/AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganisationDetails,
  removeUser,
  resendInvitation,
} from "../../redux/organisation/slice";
import { Badge } from "../../components/micro";
import RippleLoader from "../../commons/RippleLoader";
import Action from "../../commons/Action";
import ConfirmationModel from "../../commons/ConfirmationModel";
import { toast } from "react-toastify";

const MyOrganisation = () => {
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const companyId = sessionStorage.getItem("companyID");
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [isRemoveLoad, setIsRemoveLoad] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [showInviteConfirmation, setShowInviteConfirmation] = useState(false);
  const dispatch = useDispatch();

  // Selector
  const { organisationDetails, loading } = useSelector((store) => ({
    organisationDetails:
      store?.organisationData?.getOrganisationDetailsStatus?.data?.data,
    loading: store?.organisationData?.loading,
  }));

  // Get APIs
  useEffect(() => {
    let timeoutId;

    if (companyId) {
      timeoutId = setTimeout(() => {
        dispatch(getOrganisationDetails(companyId)).finally(() => {
          setLoadingData(false);
        });
      }, 1000);
    } else {
      setLoadingData(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [companyId, dispatch]);

  const handleRemoveUser = (data) => {
    setIsRemoveLoad(true);
    dispatch(removeUser(data?.id)).then((res) => {
      setIsRemoveLoad(false);
      if (res.type === "removeUser/fulfilled") {
        toast.success(res.payload.message);
        setShowRemoveConfirmation(false);
        dispatch(getOrganisationDetails(companyId));
      }
    });
  };

  const handleInviteUser = (data) => {
    setIsResend(true);
    dispatch(resendInvitation(data?.id)).then((res) => {
      setIsResend(false);
      if (res.type === "resendInvitation/fulfilled") {
        toast.success(res.payload.message);
        setShowInviteConfirmation(false);
        dispatch(getOrganisationDetails(companyId));
      }
    });
  };

  return (
    <>
      {loading || loadingData ? (
        <RippleLoader />
      ) : (
        <div className="w-full p-6">
          <div className="flex justify-between">
            <div className="flex flex-row items-center">
              <img src={myCompany} className="" />
              <div>
                <p className="text-3xl font-ebGaramond font-normal">
                  My Organization
                </p>
                <h6 className="text-sm font-poppins font-normal mb-1">
                  Overview of your company key information and dates to take
                  note off
                </h6>
              </div>
            </div>
          </div>
          <p className="border border-gray-400 mt-8"></p>

          <div className="bg-themeLight rounded-lg py-8 px-8 mt-6">
            <p className="font-ebGaramond text-theme text-xl">Directors</p>
            <div className="mt-2 space-y-3">
              {organisationDetails?.nominee_directors?.map((data) => {
                return (
                  <div className="flex justify-between">
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">Name:</p>
                      <p className="text-sm font-poppins">
                        {data?.user?.full_name}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.phone}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.email}
                      </p>
                    </div>
                    {data?.main_signee === 1 && (
                      <div className="w-40">
                        <p className="text-xs font-poppins font-normal">
                          Role:
                        </p>
                        <p className="text-xs font-poppins font-light">
                          Main Signee
                        </p>
                      </div>
                    )}
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">
                        Identity Verification Status
                      </p>
                      <Badge
                        value={data?.status}
                        className={`${
                          data?.status === "approved"
                            ? "text-white border-success bg-success"
                            : data?.status === "pending"
                            ? "bg-gray-200"
                            : ""
                        } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                      />
                    </div>
                  </div>
                );
              })}
              {organisationDetails?.resident_directors?.map((data) => {
                return (
                  <div className="flex justify-between">
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">Name:</p>
                      <p className="text-sm font-poppins">
                        {data?.user?.full_name}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.phone}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.email}
                      </p>
                    </div>
                    {data?.main_signee === 1 && (
                      <div className="w-40">
                        <p className="text-xs font-poppins font-normal">
                          Role:
                        </p>
                        <p className="text-xs font-poppins font-light">
                          Main Signee
                        </p>
                      </div>
                    )}
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">
                        Identity Verification Status
                      </p>

                      <Badge
                        value={data?.status}
                        className={`${
                          data?.status === "approved"
                            ? "text-white border-success bg-success"
                            : data?.status === "pending"
                            ? "bg-gray-200"
                            : ""
                        } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                      />
                    </div>
                  </div>
                );
              })}
              {organisationDetails?.foreign_directors?.map((data) => {
                return (
                  <div className="flex justify-between">
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">Name:</p>
                      <p className="text-sm font-poppins">
                        {data?.user?.full_name}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.phone}
                      </p>
                      <p className="text-xs font-poppins font-light">
                        {data?.user?.email}
                      </p>
                    </div>
                    {data?.main_signee === 1 && (
                      <div className="w-40">
                        <p className="text-xs font-poppins font-normal">
                          Role:
                        </p>
                        <p className="text-xs font-poppins font-light">
                          Main Signee
                        </p>
                      </div>
                    )}
                    <div className="w-40">
                      <p className="text-xs font-poppins font-normal">
                        Identity Verification Status
                      </p>

                      <Badge
                        value={data?.status}
                        className={`${
                          data?.status === "approved"
                            ? "text-white border-success bg-success"
                            : data?.status === "pending"
                            ? "bg-gray-200"
                            : ""
                        } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                      />
                    </div>
                  </div>
                );
              })}
              {!organisationDetails?.resident_directors?.length &&
                !organisationDetails?.nominee_directors?.length &&
                !organisationDetails?.foreign_directors?.length &&
                "No Directors"}
            </div>
            <p className="border border-gray-400 mt-2"></p>

            <p className="font-ebGaramond text-theme text-xl mt-4">
              Individual Shareholders
            </p>
            <div className="mt-2 space-y-3">
              {organisationDetails?.shareholders?.length > 0 ? (
                organisationDetails.shareholders.map((data) => {
                  return (
                    <div className="flex justify-between" key={data?.user?.id}>
                      <div className="w-40">
                        <p className="text-xs font-poppins font-normal">
                          Name:
                        </p>
                        <p className="text-sm font-poppins">
                          {data?.user?.full_name}
                        </p>
                        <p className="text-xs font-poppins font-light">
                          {data?.user?.phone}
                        </p>
                        <p className="text-xs font-poppins font-light">
                          {data?.user?.email}
                        </p>
                      </div>
                      <div className="w-40">
                        <p className="text-xs font-poppins font-normal">
                          Identity Verification Status
                        </p>
                        <Badge
                          value={data?.status}
                          className={`${
                            data?.status === "approved"
                              ? "text-white border-success bg-success"
                              : data?.status === "pending"
                              ? "bg-gray-200"
                              : ""
                          } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-sm font-poppins">No Shareholders</p>
              )}
            </div>

            <p className="border border-gray-400 mt-2"></p>

            <p className="font-ebGaramond text-theme text-xl mt-4">
              Corporate Shareholders
            </p>
            <div className="mt-2 space-y-3">
              {organisationDetails?.shareholders?.some(
                (data) => data?.shareholder_type === "corporate"
              ) ? (
                organisationDetails.shareholders.map((data) => {
                  if (data?.shareholder_type === "corporate") {
                    return (
                      <div
                        className="flex justify-between"
                        key={data?.user?.id}
                      >
                        <div className="w-40">
                          <p className="text-xs font-poppins font-normal">
                            Name:
                          </p>
                          <p className="text-sm font-poppins">
                            {data?.user?.full_name}
                          </p>
                          <p className="text-xs font-poppins font-light">
                            {data?.user?.phone}
                          </p>
                          <p className="text-xs font-poppins font-light">
                            {data?.user?.email}
                          </p>
                        </div>
                        <div className="w-40">
                          <p className="text-xs font-poppins font-normal">
                            Identity Verification Status
                          </p>
                          <Badge
                            value={data?.status}
                            className={`${
                              data?.status === "approved"
                                ? "text-white border-success bg-success"
                                : data?.status === "pending"
                                ? "bg-gray-200"
                                : ""
                            } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <p className="text-sm font-poppins">
                  No Corporate Shareholders
                </p>
              )}
            </div>

            <p className="border border-gray-400 mt-2"></p>
          </div>

          <div className="bg-greenLight rounded-lg py-8 px-8 mt-6">
            <div className="flex justify-between my-5">
              <p className="font-ebGaramond text-theme text-xl">System Users</p>
              <div className="">
                <button
                  className="text-base font-poppins font-normal text-theme p-1 px-2 mt-2 flex"
                  onClick={() => {
                    setEditUserData({});
                    setAddUserPopup(true);
                  }}
                >
                  <Add size="24" className="text-theme" />
                  Add user
                </button>
              </div>
            </div>
            {organisationDetails?.system_users?.map((data) => {
              return (
                <div className="flex justify-between my-6">
                  <div className="w-40">
                    <p className="text-xs font-poppins font-normal">Name:</p>
                    <p className="text-sm font-poppins">
                      {data?.user?.full_name}
                    </p>
                    <p className="text-xs font-poppins font-light">
                      {data?.user?.phone}
                    </p>
                    <p className="text-xs font-poppins font-light">
                      {data?.user?.email}
                    </p>
                  </div>
                  <div className="w-40">
                    <p className="text-xs font-poppins font-normal">Role:</p>
                    <p className="text-xs font-poppins capitalize font-light">
                      {data?.user?.role}
                    </p>
                  </div>
                  <div className="w-40">
                    <p className="text-xs font-poppins font-normal">Status</p>
                    <Badge
                      value={data?.status}
                      className={`${
                        data?.status === "active"
                          ? "text-white border-success bg-success"
                          : data?.status === "pending"
                          ? "bg-gray-200"
                          : ""
                      } font-medium capitalize inline-block text-xs mt-2 !py-1`}
                    />
                  </div>
                  <div>
                    <Action
                      optionList={
                        <>
                          <li
                            className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                            onClick={() => {
                              setEditUserData(data);
                              setAddUserPopup(true);
                            }}
                          >
                            Edit
                          </li>
                          {data?.status === "pending" && (
                            <li
                              className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                              onClick={() => {
                                setEditUserData(data);
                                setShowInviteConfirmation(true);
                              }}
                            >
                              Resend Invite
                            </li>
                          )}
                          <li
                            className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                            onClick={() => {
                              setEditUserData(data);
                              setShowRemoveConfirmation(true);
                            }}
                          >
                            Remove
                          </li>
                        </>
                      }
                    />
                  </div>
                </div>
              );
            })}
            {!organisationDetails?.system_users?.length && "No System Users"}
          </div>
        </div>
      )}
      {addUserPopup && (
        <AddUser
          companyId={companyId}
          setAddUserPopup={setAddUserPopup}
          userData={editUserData}
        />
      )}
      {showRemoveConfirmation && (
        <ConfirmationModel
          maxWidth={"max-w-72"}
          confirmText={"Remove User?"}
          isLoading={isRemoveLoad}
          onClose={() => setShowRemoveConfirmation(false)}
          onConfirm={() => {
            handleRemoveUser(editUserData);
          }}
        />
      )}
      {showInviteConfirmation && (
        <ConfirmationModel
          maxWidth={"max-w-72"}
          confirmText={"Resend Invite?"}
          isLoading={isResend}
          onClose={() => setShowInviteConfirmation(false)}
          onConfirm={() => {
            handleInviteUser(editUserData);
          }}
        />
      )}
    </>
  );
};

export default MyOrganisation;
