import React, { useState } from "react";
import { format } from "date-fns";
import { Send } from "iconsax-react";
import {
  ChatImg,
  More,
  MoreMenu,
  Next,
  PDF,
  Previous,
  User,
} from "../../../assets/svg/AllSvg";
import { headChat } from "../../../assets/image";
import FileUploadChoose from "../../../commons/FileUploadChoose";
import TextEditor from "../../tickets/TextEditor";

const messages = [
  {
    id: 1,
    text: "We have received your request on: “Service Request Name”",
    type: "received",
    timestamp: new Date(),
  },
  {
    id: 2,
    text: "Please upload your latest IC or Passport here",
    type: "received",
    timestamp: new Date(),
  },
  {
    id: 3,
    text: <FileUploadChoose />,
    type: "received",
    timestamp: new Date(),
  },
  { id: 4, text: <ChatImg />, type: "sent", timestamp: new Date() },
  {
    id: 5,
    text: "We will need your final signature. Please check your email for the relevant link to do the required signing. It’s sent to all relevant parties.",
    type: "received",
    timestamp: new Date(),
  },
  {
    id: 6,
    text: "Here’s the completed documents.",
    type: "received",
    timestamp: new Date(),
  },
  {
    id: 6,
    text: (
      <>
        <div className="flex p-4">
          <div className="flex flex-col">
            <span className="text-gray-500 text-xs">Document Name</span>
            <span className="text-gray-500 text-xs">
              Prepared By: Name 1 [EiBIZ]"
            </span>
          </div>
          <button className="" contentEditable={false}>
            <More />
          </button>
        </div>
        <div className="relative mb-2 max-w-64 text-center p-4 rounded-xl shadow-md border bg-white">
          <div className="flex text-center items-center">
            <PDF />
            <span className="text-black text-sm ml-6">abc.pdf</span>
          </div>
        </div>
      </>
    ),
    type: "received",
    timestamp: new Date(),
  },
];

const OnGoingRequest = ({ isOpen }) => {
  const [chatMessages, setChatMessages] = useState(messages);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleSendMessage = () => {
    if (message.trim() || file) {
      setChatMessages([
        ...chatMessages,
        {
          id: chatMessages.length + 1,
          text: message,
          type: "sent",
          timestamp: new Date(),
          file,
        },
      ]);
      setMessage("");
      setFile(null);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const renderFilePreview = (file) => {
    if (!file) return null;

    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type.split("/")[0];

    if (fileType === "image") {
      return (
        <img
          src={fileUrl}
          alt="preview"
          className="max-w-[200px] max-h-[200px] rounded-lg"
        />
      );
    }

    if (fileType === "application" && file.type.includes("pdf")) {
      return (
        <div className="mt-2">
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            {file.name}
          </a>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="space-y-5">
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <p className="text-4xl font-ebGaramond">
          Your On-Going Service Requests
        </p>
      </div>
      <div className="mt-4 border border-gray-300 p-6 rounded-lg">
        <div className="flex items-center">
          <p className="text-2xl font-ebGaramond">Service Request Name</p>
          <span className="text-green text-xs font-medium me-2 px-2.5 rounded-xl border border-blue-800 ml-4">
            Pending from Client
          </span>
        </div>
        <div className="flex mt-2">
          <div>
            <p className="text-sm font-poppins">Service Request ID: 0001</p>
            <p className="text-sm font-poppins flex">
              To Be Completed By:{" "}
              <span className="text-theme text-sm font-poppins ml-2">
                24/05/2024
              </span>
            </p>
          </div>

          <div className="ml-4">
            <p className="text-sm font-poppins">
              Company Name: Company Pte Ltd
            </p>
            <p className="text-xs font-poppins">Customer Name: Name 1</p>
          </div>
        </div>
        <div className="mt-4 border border-gray-300 p-4 rounded-lg">
          <div className="flex-1 overflow-y-auto p-4">
            {chatMessages.map((msg) => (
              <div
                key={msg.id}
                className={`mb-4 flex ${
                  msg.type === "sent" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`flex items-start gap-2 ${
                    msg.type === "sent" ? "flex-row-reverse" : ""
                  }`}
                >
                  <User />
                  <div
                    className={`inline-block p-2 rounded-lg ${
                      msg.type === "sent"
                        ? "bg-lightThemeSecondary"
                        : "bg-chatBg"
                    }`}
                  >
                    {msg.text && <p>{msg.text}</p>}
                    {renderFilePreview(msg.file)}
                    <div className="text-xs text-gray-500 mt-1">
                      {format(msg.timestamp, "dd/MM/yyyy HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <TextEditor isOpen={isOpen} setMessage={setMessage} />
          <div className="flex justify-end">
            <button
              onClick={handleSendMessage}
              className="bg-theme text-white p-2 rounded-lg"
            >
              submit reply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnGoingRequest;
