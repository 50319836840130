// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  createTicketStatus: {
    data: null,
  },
  getTicketsListStatus: {
    data: null,
  },
  getTicketsStatus: {
    data: null,
    loading: false,
  },
  replayTicketStatus: {
    data: null,
  },
  deleteTicketStatus: {
    data: null,
  },
};
