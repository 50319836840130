import React from "react";
import { Registration, myCompany } from "../../assets/image";
import "../auth/Auth.css";
import "../dashboard/dashboard.css";
import { Calendar } from "iconsax-react";

const MyCompany = () => {
return (
<>
    <div className="w-full p-6">
        <div className="flex justify-between">
            <div className="flex flex-row items-center">
                <img src={myCompany} className="" />
                <div>
                    <p className="text-3xl font-ebGaramond font-normal">
                        My Company Information
                    </p>
                    <h6 className="text-sm font-poppins font-normal mb-1">
                        Overview of your company key information and dates to take note
                        off
                    </h6>
                </div>
            </div>
        </div>
        <p className="border border-gray-400 mt-8"></p>

        <div className="grid grid-cols-3 gap-5 mt-10">
            <div className="flex">
                <div className="">
                    <div className="box-content w-80 main-view p-2 m-0">
                        <div className="p-1">
                            <div>
                                <p className="text-xl font-ebGaramond font-normal ml-8">
                                    My Company Pte Ltd
                                </p>
                                <div className="flex">
                                    <img src={Registration} alt="logo" className="object-cover w-6 h-6" />
                                    <div className="ml-2">
                                        <p className="text-sm font-ebGaramond font-normal text-gray-700">
                                            Registration No.
                                        </p>
                                        <p className="text-sm font-ebGaramond font-normal text-black">
                                            00000000000X
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-1 ml-8">
                                <p className="text-sm font-ebGaramond font-normal text-gray-700">
                                    Incorporation Date
                                </p>
                                <p className="text-sm font-ebGaramond font-normal">
                                    DD/MM/YYYY
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="box-content w-80 main-view p-2">
                        <div className="p-1">
                            <div className="flex">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M24 39C27.3137 39 30 36.3137 30 33C30 29.6863 27.3137 27 24 27C20.6863 27 18 29.6863 18 33C18 36.3137 20.6863 39 24 39Z"
                                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                                    <path d="M19.65 37.3516L14 43.0016" stroke="#065699" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 20V33H13" stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M35 33V15L24 4L13 15V33" stroke="#065699" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M21 17C21 16.2044 21.3161 15.4413 21.8787 14.8787C22.4413 14.3161 23.2044 14 24 14C24.7956 14 25.5587 14.3161 26.1213 14.8787C26.6839 15.4413 27 16.2044 27 17"
                                        stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M21 24V21H27V24" stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M3 19L6 10H18" stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M12 19H3" stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M30 10H42L45 19H36" stroke="#065699" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M42 20V33H35" stroke="#065699" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                </svg>
                                <div>
                                    <p className="text-xl font-ebGaramond font-normal ml-2">
                                        Registered Office Address
                                    </p>
                                    <div className="ml-2">
                                        <p className="text-sm font-ebGaramond font-normal text-gray-700 mt-1">
                                            Address:
                                        </p>
                                        <p className="text-sm font-ebGaramond font-normal text-black">
                                            Blk 123 Walk the Lane #01-11 <br /> Singapore, 123456{" "}
                                            <br />
                                            Singapore
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-themeLight rounded-lg py-8 px-8 mt-6">
            <div className="flex">
                <Calendar size="24" className=" text-theme" />
                <p className="font-ebGaramond text-theme text-xl ml-2">
                    Dates to note:
                </p>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="w-40">
                    <p className="text-xs font-poppins font-light">
                        Financial Year End
                    </p>
                    <p className="text-sm font-poppins">31 Dec 2023</p>
                </div>
                <div className="w-80">
                    <p className="text-xs font-poppins font-light">
                        Financial year end (FYE) is the completion of a one
                        yearaccounting period. It will determine when your corporate
                        filings and taxes are due.
                    </p>
                </div>
            </div>
            <p className="border border-gray-400 mt-2"></p>

            <div className="mt-2 flex justify-between">
                <div className="w-40">
                    <p className="text-xs font-poppins font-light">
                        Annual General Meeting (AGM)
                    </p>
                    <p className="text-sm font-poppins">31 Dec 2023</p>
                </div>
                <div className="w-80">
                    <p className="text-xs font-poppins font-light">
                        EiBiz will prepare and send you documents to sign!
                    </p>
                </div>
            </div>
            <p className="border border-gray-400 mt-2"></p>

            <div className="mt-2 flex justify-between">
                <div className="w-40">
                    <p className="text-xs font-poppins font-light">Annual Return</p>
                    <p className="text-sm font-poppins">31 Dec 2023</p>
                </div>
                <div className="w-80">
                    <p className="text-xs font-poppins font-light">
                        Annual Return (an electronic form that contains important
                        information about the company) needs to be submitted to
                        Accounting & Corporate Regulatory Authority (ACRA), by EiBIZ, as
                        soon as your AGM has been held.
                    </p>
                </div>
            </div>
            <p className="border border-gray-400 mt-2"></p>

            <div className="mt-2 flex justify-between">
                <div className="w-40">
                    <p className="text-xs font-poppins font-light">
                        Tax Return (Form C/C-S)
                    </p>
                    <p className="text-sm font-poppins">31 Dec 2023</p>
                </div>
                <div className="w-80">
                    <p className="text-xs font-poppins font-light">
                        Tax return (Form C/C-S) needs to be submitted by EiBiz to Inland
                        Revenue Authority of Singapore (IRAS) for the Year of Assessment
                        2024 corresponding to your Financial Years ending on 31 Dec
                        2023.
                    </p>
                </div>
            </div>
            <p className="border border-gray-400 mt-2"></p>
        </div>

        <div className="bg-greenLight rounded-lg py-8 px-8 mt-6">
            <div className="flex">
                {/*
                <SmartHome size="24" className=" text-theme" /> */}
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.29688 14.5859V23.3359H10.2135V15.3151H14.5885V18.9609" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path d="M27.7083 14.5859V23.3359H24.0625" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path
                        d="M17.5 29.8984C19.9162 29.8984 21.875 27.9397 21.875 25.5234C21.875 23.1072 19.9162 21.1484 17.5 21.1484C15.0838 21.1484 13.125 23.1072 13.125 25.5234C13.125 27.9397 15.0838 29.8984 17.5 29.8984Z"
                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                    <path d="M14.3307 28.6953L10.2109 32.8151" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path d="M17.5 18.9583V15.3125H24.7917V20.4167" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path d="M8.02344 3.64844L5.83594 9.48177H29.1693L26.9818 3.64844H8.02344Z" stroke="#065699"
                        strokeWidth="2" strokeLinejoin="round" />
                    <path d="M11.6719 9.48177L13.1302 3.64844" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path d="M21.875 3.64844L23.3333 9.48177" stroke="#065699" strokeWidth="2"
                        strokeLinejoin="round" />
                    <path d="M17.5 3.64844V9.48177" stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                    <path
                        d="M5.83594 9.48438V10.2135C5.83594 10.9871 6.14323 11.729 6.69021 12.2759C7.23719 12.8229 7.97906 13.1302 8.7526 13.1302C9.52615 13.1302 10.268 12.8229 10.815 12.2759C11.362 11.729 11.6693 10.9871 11.6693 10.2135V9.48438"
                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                    <path
                        d="M11.6719 9.48438V10.2135C11.6719 10.9871 11.9792 11.729 12.5261 12.2759C13.0731 12.8229 13.815 13.1302 14.5885 13.1302C15.3621 13.1302 16.104 12.8229 16.6509 12.2759C17.1979 11.729 17.5052 10.9871 17.5052 10.2135V9.48438"
                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                    <path
                        d="M17.5 9.48438V10.2135C17.5 10.9871 17.8073 11.729 18.3543 12.2759C18.9013 12.8229 19.6431 13.1302 20.4167 13.1302C21.1902 13.1302 21.9321 12.8229 22.4791 12.2759C23.026 11.729 23.3333 10.9871 23.3333 10.2135V9.48438"
                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                    <path
                        d="M23.3359 9.48438V10.2135C23.3359 10.9871 23.6432 11.729 24.1902 12.2759C24.7372 12.8229 25.4791 13.1302 26.2526 13.1302C27.0262 13.1302 27.768 12.8229 28.315 12.2759C28.862 11.729 29.1693 10.9871 29.1693 10.2135V9.48438"
                        stroke="#065699" strokeWidth="2" strokeLinejoin="round" />
                </svg>

                <p className="font-ebGaramond text-theme text-xl ml-2">
                    Principal Activities
                </p>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="w-36">
                    <p className="text-xs font-poppins font-light">
                        Primary Activities [12345] E-Commerce
                    </p>
                </div>
                <div className="w-80">
                    <p className="text-xs font-poppins font-light">
                        Secondary Activity <br />
                        [54321] Retail stores
                    </p>
                </div>
            </div>
        </div>
    </div>
</>
);
};

export default MyCompany;