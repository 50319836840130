import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userIcon, Logo, deleteIcon, goBack, goOn } from "../../assets/image";
import { InfoCircle, Add } from "iconsax-react";
import { Button } from "@headlessui/react";

export default function InviteCompany({handleStepClick}) {
  const navigate = useNavigate();

  const handleNominee = () => {
    navigate("/nominee");
  };

  return (
    <div className="card border p-4 shadow-lg bg-white">
      <div className="p-2">
        <p className="font-poppins text-sm">Thirdly,</p>
        <p className="font-ebGaramond font-normal text-2xl">
          Invite company members
        </p>
      </div>
      <div className="w-full flex">
        <Button className="w-1/2 border px-4 my-4 mr-2 rounded-md hover:bg-lightPrimaryShades">
          <div className="w-full flex py-4">
            <div className="w-60 py-1 px-3">
              <p className="font-poppins text-base font-normal w-60">
                Use my local resident director
              </p>
              <Link
                to="/"
                className="border-b border-blue-700 text-blue-700 font-normal text-xs font-poppins text-left -ml-28"
              >
                Eligibility Criteria
              </Link>
            </div>
          </div>
        </Button>
        <Button
          className="w-1/2 border p-2 my-4 rounded-md hover:bg-lightPrimaryShades"
          onClick={handleNominee}
        >
          <div className="w-full flex py-4">
            <div>
              <img className="h-14 w-14" src={Logo} alt="logo" />
            </div>
            <div className="w-60 py-1 px-3">
              <p className="font-poppins text-base font-normal w-60 -ml-2">
                Use EiBIZ’s Nominee Director
              </p>
              <p className="font-poppins font-normal text-xs pb-0 mt-2 text-left">
                If you are based overseas or need a time buffer to get a local
                director
              </p>
            </div>
          </div>
        </Button>
      </div>

      <div className="p-2 w-full flex">
        <div>
          <div className="mt-2">
            <InfoCircle size="24" className="fill-lightInfo text-white" />
          </div>
        </div>
        <div className="ml-2">
          <p className="font-poppins font-normal text-xs pb-0 mt-2">
            Singapore law mandates that all incorporated companies must have at
            least one local resident director and one shareholder.
            <br />
            <br />
            You can use your own Local Director (Singaporean Citizen, PR or
            Entrepass holder) or sign up for our Nominee Local Director service.
          </p>
          <Link
            to="/"
            className="border-b border-blue-700 text-blue-700 font-normal text-xs font-poppins"
          >
            Read More
          </Link>
        </div>
      </div>

      {/* 1 */}
      <div className="mt-4 p-2">
        <p className="font-poppins font-normal text-xl">1. Directors</p>
        <p className="font-poppins font-normal text-xs pb-0 mt-2">
          An email invitation will be sent to company members for identity
          verification.
        </p>
        <div className="border p-2 my-4 rounded-md bg-lightSecondaryShades">
          <div className="w-full flex py-4">
            <div className="w-1/3">
              <p className="px-3 font-poppins font-normal text-sm">Directors</p>
              <p className="px-3 font-poppins font-normal text-xs pb-0 mt-2">
                At least 1 local resident director required
              </p>
            </div>
            <p className="border" />
            <div className="w-10/12 px-6">
              <div className="bg-white w-96 rounded-lg flex py-4 pr-2 shadow-lg justify-between">
                <div className="flex px-4">
                  <img className="h-14 w-14" src={userIcon} />
                  <div className="ml-2">
                    <p className="font-poppins font-normal text-sm">Name</p>
                    <p className="font-poppins font-normal text-xs">
                      Email@rmail.com
                    </p>
                  </div>
                </div>
                <div>
                  <button className="px-3 font-poppins font-normal text-xs border-blue-800 rounded-full border h-8 text-blue-800">
                    Main Signee
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <button className="px-3 flex flex-row font-poppins items-center font-normal text-xs border-blue-800 rounded-md border h-8 text-blue-800">
                  <Add size="20" className=" text-blue-800" />
                  Add director
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="border" />
      {/* 2 */}
      <div className="mt-4 p-2">
        <p className="font-poppins font-normal text-xl">2. Shareholders</p>
        <p className="font-poppins font-normal text-xs pb-0 mt-2">
          An email invitation will be sent to company members for identity
          verification.
        </p>
        <div className="border p-2 my-4 rounded-md bg-lightSecondaryShades">
          <div className="w-full flex py-4">
            <div className="w-1/3">
              <p className="px-3 font-poppins font-normal text-sm">
                Shareholders
              </p>
              <p className="px-3 font-poppins font-normal text-xs pb-0 mt-2">
                Starting at SGD 300 per year for corporate secretary services
              </p>
            </div>
            <p className="border" />
            <div className="w-10/12 px-6">
              <div className="bg-white w-96 rounded-lg flex py-4 shadow-lg justify-between">
                <div className="flex px-4">
                  <img className="h-14 w-14" src={userIcon} />
                  <div className="ml-4">
                    <p className="font-poppins font-normal text-sm">Name</p>
                    <p className="font-poppins font-normal text-xs">
                      Email@rmail.com
                    </p>
                  </div>
                </div>
                <div>
                  <button className="px-3 text-blue-800">
                    <img src={deleteIcon} />
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <button className="px-3 flex flex-row font-poppins items-center font-normal text-xs border-blue-800 rounded-md border h-8 text-blue-800">
                  <Add size="20" className=" text-blue-800" />
                  Add shareholder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="border" />
      {/* button */}
      <div className="flex mt-4 justify-end">
        <button
          className={
            "bg-white hover:bg-grey-700 text-theme font-bold py-2 px-4 rounded flex border-theme border-2"
          }
          onClick={() => handleStepClick(2)}
        >
          <img className="w-5 h-5" src={goBack} />
          <span className="ml-2 font-ebGaramond font-normal text-sm">BACK</span>
        </button>
        <button
          className={
            "bg-theme hover:bg-grey-700 text-white font-bold py-2 px-4 rounded flex ml-5"
          }
          onClick={() => handleStepClick(4)}
        >
          <span className="mr-2 font-ebGaramond font-normal text-sm">
            SAVE & PROCEED ON
          </span>
          <img className="w-5 h-5" src={goOn} />
        </button>
      </div>
    </div>
  );
}
