import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const Pagination = (props) => {
  const handlePagination = (current) => {
    props.pagination(current);
  };

  return (
    <>
      <div>
        <ul className="flex items-center justify-center gap-3 rounded-full p-1.5">
          <li className="page-item">
            <Link
              to={"#"}
              className={`flex items-center justify-center rounded-full`}
              onClick={() => handlePagination(Math.max(props.current - 1, 1))}
            >
              <span className="xl:text-lg text-base font-bold mr-1 pt-0.5">
                <ArrowLeft2 size="16" />
              </span>
            </Link>
          </li>
            <li className="page-item">
              <Link
                to={"#"}
                className={`flex items-center justify-center rounded-full ${
                  props.current === props.total
                    ? "hidden"
                    : props.current < props.total
                    ? ""
                    : ""
                }`}
                onClick={() =>
                  handlePagination(Math.min(props.current + 1, props.total))
                }
              >
                <span className="xl:text-lg text-base font-bold pt-0.5">
                  <ArrowRight2 size="16" />
                </span>
              </Link>
            </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
