import React from "react";
import { ArrowForward } from "../../../assets/svg/AllSvg";
import { waiting } from "../../../assets/image";
import { useNavigate } from "react-router-dom";

const Approver = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-2xl space-y-5">
      <div className="font-ebGaramond border-b border-secondary_grey pb-4">
        <p className="text-xl mb-3">Thank You!</p>
        <p className="text-4xl max-w-lg mb-7">
          We are now waiting confirmation with your approver :)
        </p>
        <img src={waiting} alt="thanks" />
      </div>
      <p>You can now check your service request status here:</p>
      <button className="text-theme flex items-center uppercase font-semibold"   onClick={() => {
          navigate("/service-request");
        }}>
        service request <ArrowForward className="ml-2" fill='#065699' />
      </button>
    </div>
  );
};

export default Approver;
