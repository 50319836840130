import React, { useState } from "react";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { useNavigate } from "react-router-dom";
import { headChat } from "../../../assets/image";
import FileUploadChoose from "../../../commons/FileUploadChoose";
import ReactSelect from "react-select";

const OpenBankAccount = () => {
  const [particularType, setParticularType] = useState("");
  const ParticularTypeOptions = [
    { value: "ic", label: "IC" },
    { value: "passport", label: "Passport" },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="space-y-5">
        <BackButton
          onClick={() => {
            navigate("/service-request");
          }}
        />
        <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
          <img src={headChat} alt="img" />
          <div className="space-y-2 font-ebGaramond">
            <p className="text-xl">We see that you will need a request on:</p>
            <p className="text-3xl">Open Bank Account</p>
          </div>
        </div>
        <div className="space-y-3 border-b-2 border-secondary_grey pb-4">
          <p className="text-2xl font-ebGaramond">
            Let us guide u though the process:
          </p>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>1.</span>
            <p>Provide us with some personal details </p>
          </div>
          <p className="text-sm">
            i. You can choose either IC (Front & Back) or Passport
          </p>
          <ReactSelect
            className={"p-0"}
            id="particularType"
            placeholder="Particular Type"
            options={ParticularTypeOptions}
            isLoading={false}
            value={particularType}
            onChange={(e) => {
              setParticularType(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
        </div>
        {particularType && (
          <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
            <p className="text-sm">
              &#8226; {particularType.label === "IC" ? "IC front" : "Passport"}
            </p>
            <FileUploadChoose />
            {particularType.label === "IC" && (
              <>
                <p className="text-sm">&#8226; IC back</p>
                <FileUploadChoose />
              </>
            )}
          </div>
        )}
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">ii. Proof of Address</p>
          <FileUploadChoose />
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>2.</span>
            <p>Provide us with some company documents</p>
          </div>
          <p className="text-sm">i. Company Bizfile + Constitution</p>
          <FileUploadChoose />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">ii. Company Website</p>
          <InputWithLabel
            className={"border"}
            idFromName={"website"}
            type={"url"}
            label={"Website"}
          />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">iii. Company Sales Contract, Quotation</p>
          <FileUploadChoose />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">iv. Company Supplier Name Listing</p>
          <FileUploadChoose />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">iv. Rental Agreement</p>
          <FileUploadChoose />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">iv. Rental Agreement</p>
          <FileUploadChoose />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <p className="text-sm">v. Client contact HP no. and Email</p>
          <InputWithLabel
            className={"border"}
            idFromName={"handphone_number"}
            type={"number"}
            label={"Handphone Number"}
          />
          <InputWithLabel
            className={"border"}
            idFromName={"email"}
            type={"email"}
            label={"Email"}
          />
        </div>
        <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>3.</span>
            <p>EiBIZ will be reviewing the provided information</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>4.</span>
            <p>
              EiBIZ will be arrange an Online Video call reference & liaising
              with bank
            </p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>5.</span>
            <p>Bank account created!</p>
          </div>
          <p className="text-sm">
            Once the request is opened. You can easily manage it over at
            “On-Going” service page.
          </p>
        </div>
        <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
          <p className="font-semibold">Total Payable :</p>
          <div>
            <p className="text-xs">Amount</p>
            <p className="text-xl">$5,000</p>
          </div>
        </div>
        <div className="box !rounded-xl flex items-center justify-between">
          <button
            disabled={true}
            className={`${false ? "btn-theme" : "btn-disabled"}  uppercase`}
          >
            Proceed with request
          </button>
          <div>
            <p className="text-xs">Total:</p>
            <p className="text-xl">$5,000</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenBankAccount;
