import React from "react";
import { headChat } from "../../../assets/image";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { useNavigate } from "react-router-dom";

const ChangeCompanyName = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-5">
        <BackButton
          onClick={() => {
            navigate("/service-request");
          }}
        />
        <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
          <img src={headChat} alt="img" />
          <div className="space-y-2 font-ebGaramond">
            <p className="text-xl">We see that you will need a request on:</p>
            <p className="text-3xl">Change of Company Name</p>
          </div>
        </div>
        <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
          <p className="text-2xl font-ebGaramond">
            Let us guide u though the process:
          </p>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>1.</span>
            <p>Check if the New Company Name is available</p>
          </div>
          <p className="text-sm">
            Before we change the name, you would need to make sure you have a
            unique company name.
          </p>
          <div>
            <div className="flex items-center gap-5">
              <div className="w-full">
                <InputWithLabel
                  className={"border"}
                  idFromName={"new_company_name"}
                  type={"text"}
                  label={"New Company Name"}
                />
              </div>
              <button className="btn-theme whitespace-nowrap">
                Check availability
              </button>
            </div>
            <p className="text-sm text-gray-400">
              *Company name availability is subject to final confirmation from
              regulating authority
            </p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>2.</span>
            <p>We will prepare relevant resolution</p>
          </div>

          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>3.</span>
            <p>Obtain signed resolution</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>4.</span>
            <p>
              We will lodge the changes to ACRA for the change of Company Name
            </p>
          </div>
          <p className="text-sm">
            Once the request is opened. You can easily manage it over at
            “On-Going” service page.
          </p>
        </div>
        <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
          <p className="font-semibold">Total Payable :</p>
          <div>
            <p className="text-xs">1/3 used</p>
            <p>Free</p>
          </div>
        </div>
        <div className="box !rounded-xl flex items-center justify-between">
          <button
            className={`btn-theme uppercase`}
            onClick={() => {
              navigate("/approver");
            }}
          >
            Proceed with request
          </button>
          <div>
            <p className="text-xs">Total:</p>
            <p>Free</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCompanyName;
