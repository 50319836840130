import React, { useState } from "react";
import { paymentGateway, vise, goBack, master, cart } from "../../assets/image";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function PaymentGateway() {
  const [selectedOption, setSelectedOption] = useState("Select a Card");
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [errors, setErrors] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  const navigate = useNavigate();

  const handleSelect = (option) => {
    setSelectedOption(option);
    setErrors((prevErrors) => ({ ...prevErrors, selectedOption: null }));
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    navigate("/success");
  };

  const validateForm = () => {
    const errors = {};
    if (selectedOption === "Select a Card") errors.selectedOption = "* Select card is required."; 
    if (!nameOnCard) errors.nameOnCard = "* Name on card is required.";
    if (!cardNumber) errors.cardNumber = "* Card number is required.";
    if (!expiryMonth) errors.expiryMonth = "* Expiry month is required.";
    if (!expiryYear) errors.expiryYear = "* Expiry year is required.";
    if (!securityCode) errors.securityCode = "* Security code is required.";
    if (!address) errors.address = "* Address is required.";
    if (!city) errors.city = "* City is required.";
    if (!state) errors.state = "* State is required.";
    if (!zipCode) errors.zipCode = "* ZIP code is required.";
    if (!email) {
      errors.email = "* Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "* Email address is invalid.";
    }
    if (!phone) errors.phone = "* Phone number is required.";

    return errors;
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    }

    switch (field) {
      case "selectedOption":
        setSelectedOption(value);
        break;
      case "nameOnCard":
        setNameOnCard(value);
        break;
      case "cardNumber":
        setCardNumber(value);
        break;
      case "expiryMonth":
        setExpiryMonth(value);
        break;
      case "expiryYear":
        setExpiryYear(value);
        break;
      case "securityCode":
        setSecurityCode(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "zipCode":
        setZipCode(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <form
        onSubmit={handleFormSubmit}
        className="card border p-6 shadow-lg bg-white rounded-lg"
      >
        <button
          type="button"
          className="bg-white hover:bg-grey-700 text-theme font-bold rounded flex"
          onClick={() => navigate(-1)}
        >
          <img className="w-5 h-5" src={goBack} alt="Go Back" />
          <span className="ml-2 font-poppins font-normal text-sm">BACK</span>
        </button>
        <div className="p-2 flex items-center mt-8">
          <img
            className="h-32 w-28"
            src={paymentGateway}
            alt="Payment Gateway"
          />
          <div>
            <p className="font-ebGaramond font-normal text-xl mt-1">
              You are all set
            </p>
            <p className="font-ebGaramond font-normal text-3xl">
              To Checkout Now!
            </p>
          </div>
        </div>
        <p className="border border-gray-400 mt-2" />

        <div className="">
          <p className="font-ebGaramond text-lg font-normal my-5">
            Payment Method
          </p>
          <div>
            <div className="w-full">
              <Menu as="div" className="relative w-full">
                <MenuButton
                  className={`inline-flex w-full justify-between gap-x-1.5 rounded-md px-3 py-2 text-sm font-normal
${selectedOption !== "Select a Card" ? "text-black" : "text-gray-400"}
bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2
focus:ring-Black-500`}
                >
                  {selectedOption}
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </MenuButton>
                <MenuItems className="absolute w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`block w-full px-4 py-2 text-left text-sm ${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          }`}
                          onClick={() => handleSelect("Credit Card")}
                        >
                          Credit Card
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className={`block w-full px-4 py-2 text-left text-sm ${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          }`}
                          onClick={() => handleSelect("Debit Card")}
                        >
                          Debit Card
                        </button>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
              {errors.selectedOption && ( 
                <p className="text-red-500 text-sm">{errors.selectedOption}</p>
              )}
            </div>

            <div className="mt-3">
              <input
                type="text"
                value={nameOnCard}
                onChange={(e) => handleInputChange(e, "nameOnCard")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  errors.nameOnCard ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                placeholder="Name on Card*"
              />
              {errors.nameOnCard && (
                <p className="text-red-500 text-sm">{errors.nameOnCard}</p>
              )}
            </div>

            <div className="mt-3">
              <input
                type="number"
                value={cardNumber}
                onChange={(e) => handleInputChange(e, "cardNumber")}
                className={`mt-1 block w-full px-3 py-2 border ${
                  errors.cardNumber ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                placeholder="Card Number*"
              />
              {errors.cardNumber && (
                <p className="text-red-500 text-sm">{errors.cardNumber}</p>
              )}
            </div>

            <div className="flex w-full justify-between">
              <div className="mt-3 flex-1">
                <input
                  type="text"
                  value={expiryMonth}
                  onChange={(e) => handleInputChange(e, "expiryMonth")}
                  className={`block w-full px-3 py-2 border ${
                    errors.expiryMonth ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                  placeholder="Expiry Month*"
                />
                {errors.expiryMonth && (
                  <p className="text-red-500 text-sm">{errors.expiryMonth}</p>
                )}
              </div>
              <div className="mt-3 flex-1 mx-2">
                <input
                  type="text"
                  value={expiryYear}
                  onChange={(e) => handleInputChange(e, "expiryYear")}
                  className={`block w-full px-3 py-2 border ${
                    errors.expiryYear ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                  placeholder="Expiry Year*"
                />
                {errors.expiryYear && (
                  <p className="text-red-500 text-sm">{errors.expiryYear}</p>
                )}
              </div>
              <div className="mt-3 flex-1">
                <input
                  type="text"
                  value={securityCode}
                  onChange={(e) => handleInputChange(e, "securityCode")}
                  className={`block w-full px-3 py-2 border ${
                    errors.securityCode ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                  placeholder="Security Code*"
                />
                {errors.securityCode && (
                  <p className="text-red-500 text-sm">{errors.securityCode}</p>
                )}
              </div>
            </div>

            <p className="font-ebGaramond text-lg font-normal my-4">
              Billing Information
            </p>
            <div>
              <div className="mt-3">
                <input
                  type="text"
                  value={address}
                  onChange={(e) => handleInputChange(e, "address")}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    errors.address ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                  placeholder="Address*"
                />
                {errors.address && (
                  <p className="text-red-500 text-sm">{errors.address}</p>
                )}
              </div>
              <div className="flex w-full justify-between">
                <div className="mt-3 flex-1">
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => handleInputChange(e, "city")}
                    className={`block w-full px-3 py-2 border ${
                      errors.city ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                    placeholder="City*"
                  />
                  {errors.city && (
                    <p className="text-red-500 text-sm">{errors.city}</p>
                  )}
                </div>
                <div className="mt-3 flex-1 mx-2">
                  <input
                    type="text"
                    value={state}
                    onChange={(e) => handleInputChange(e, "state")}
                    className={`block w-full px-3 py-2 border ${
                      errors.state ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                    placeholder="State*"
                  />
                  {errors.state && (
                    <p className="text-red-500 text-sm">{errors.state}</p>
                  )}
                </div>
                <div className="mt-3 flex-1">
                  <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => handleInputChange(e, "zipCode")}
                    className={`block w-full px-3 py-2 border ${
                      errors.zipCode ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                    placeholder="ZIP Code*"
                  />
                  {errors.zipCode && (
                    <p className="text-red-500 text-sm">{errors.zipCode}</p>
                  )}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="mt-3 flex-1">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleInputChange(e, "email")}
                    className={`block w-full px-3 py-2 border ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                    placeholder="Email*"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="mt-3 flex-1 mx-2">
                  <input
                    type="number"
                    value={phone}
                    onChange={(e) => handleInputChange(e, "phone")}
                    className={`block w-full px-3 py-2 border ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-black-500 focus:border-black-500 sm:text-sm`}
                    placeholder="Phone*"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>
              </div>
              <label className="flex items-center ml-1 mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 bg-blue-900"
                  name="createHolding"
                />
                <span className="ml-2 font-poppins font-light text-sm">
                  Saved as default billing address
                </span>
              </label>
            </div>

            <p className="font-ebGaramond text-lg font-normal my-4">
              Billing Options
            </p>
            <div className="flex items-center border border-gray-300 p-3 rounded-lg">
              <div
                onClick={() => setIsToggled(!isToggled)}
                className={`w-10 h-4 flex items-center rounded-full cursor-pointer ${
                  isToggled ? "bg-theme" : "bg-gray-400"
                }`}
              >
                <div
                  className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out border ${
                    isToggled ? "translate-x-6" : ""
                  }`}
                ></div>
              </div>
              <label className="ml-2 font-poppins text-sm font-light leading-6">
                Auto deduct my yearly renewal
              </label>
            </div>
          </div>

          {/* Button */}
          <div className="mt-5">
            <button
              type="submit"
              className={`bg-lightGreen ${!termsAccepted ? 'opacity-50 cursor-not-allowed' : 'hover:bg-grey-700'} text-white font-bold py-2 px-4 rounded flex w-full justify-center`}
              disabled={!termsAccepted} 
            >
              <span className="mr-2 font-poppins font-normal text-sm">
                CHECK OUT
              </span>
              <img className="w-5 h-5" src={cart} alt="Cart" />
            </button>
          </div>
          <label className="flex items-center ml-1 mt-4">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 bg-blue-900"
              name="createHolding"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <span className="ml-2 font-poppins font-light text-xs">
              By continuing, you agree to EiBIZ Terms and Conditions.
            </span>
          </label>
        </div>
      </form>
    </>
  );
}
