import React, { useEffect, useRef, useState } from "react";
import { MoreVert } from "../assets/svg/AllSvg";

const Action = ({ optionList }) => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const toggling = () => setOpen(!open);
  useEffect(() => {
    function handler(event) {
      if (!cancelButtonRef.current?.contains(event.target)) {
        setOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);
  return (
    <>
      <div
        className="relative cursor-pointer py-2 px-3"
        ref={cancelButtonRef}
        onClick={() => toggling()}
      >
        <MoreVert />
        {open && (
          <>
            <ul className="absolute top-[calc(100%+0.4rem)] min-w-[10rem] right-0 bg-white shadow-md rounded-lg overflow-auto z-10">
              {optionList}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default Action;
