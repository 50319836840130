import React from "react";
import { headChat } from "../../assets/image";
import {
  BankCloseIcon,
  BankOpenIcon,
  BusinessActivitiesIcon,
  CompanyIcon,
  LocalDirectorIcon,
  ParticularsIcon,
  RegisteredAddressIcon,
  ShareholdersIcon,
  YearEndIcon,
} from "../../assets/svg/AllSvg";
import Services from "./Services";

const ServiceRequest = () => {
  const list = [
    {
      icon: <LocalDirectorIcon />,
      heading: "Appointment of New Local Director",
      subHeading: "Appointment of a new director to the company",
      appointment: "local-director",
      service: "LocalDirector",
    },
    {
      icon: <LocalDirectorIcon />,
      heading: "Appointment of New Foreign Director",
      subHeading: "Appointment of a new foreign director to the company",
      appointment: "local-director",
      service: "LocalDirector",
    },
    {
      icon: <BankOpenIcon />,
      heading: "Open Bank Account",
      subHeading: "Opening of Company Bank Account",
      appointment: "open-bank-account",
      service: "BankAccount",
    },
    {
      icon: <BankCloseIcon />,
      heading: "Close Bank Account",
      subHeading: "Closing of Company Bank Account",
      appointment: "close-bank-account",
      service: "BankAccount",
    },
    {
      icon: <CompanyIcon />,
      heading: "Change of Company Name",
      subHeading: "Need a change in name for your company",
      appointment: "change-company-name",
      service: "ChangeCompanyName",
    },
    {
      icon: <BusinessActivitiesIcon />,
      heading: "Change of Business Activities",
      subHeading:
        "If you want to add a new business activity or modify your existing business activities.",
      appointment: "change-business-activity",
      service: "ChangeCompanyActivities",
    },
    {
      icon: <RegisteredAddressIcon />,
      heading: "Change of Company Registered Address",
      subHeading:
        "Change company registered company address and file it on ACRA",
      appointment: "change-company-address",
      service: "ChangeCompanyAddress",
    },
    {
      icon: <ParticularsIcon />,
      heading: "Change of Director’s Particulars",
      subHeading: "Changing of Director’s Particulars",
      appointment: "change-director-particular",
      service: "ChangeParticulars",
    },
    {
      icon: <ParticularsIcon />,
      heading: "Change of Shareholder’s Particulars",
      subHeading: "Changing of Shareholder’s Particulars",
      appointment: "change-shareholder-particular",
      service: "ChangeParticulars",
    },
    {
      icon: <YearEndIcon />,
      heading: "Change of Financial Year End",
      subHeading: "Changing of your Company Financial Year End",
      appointment: "change-financial-year-end",
      service: "ChangeFinancialYear",
    },
    {
      icon: <ShareholdersIcon />,
      heading: "Allotment of Shares to Existing/New Shareholders",
      subHeading:
        "Allot, split, and transfer shares among existing and new shareholders. Payment may be necessary if you introduce new shareholders, surpassing the limit covered by your Corporate Secretary subscription.",
      appointment: "allotment-transfer-new-shareholder",
      service: "NewShareholders",
    },
    {
      icon: <YearEndIcon />,
      heading: "Applying for Passes",
      subHeading: "Applying for Passes",
      appointment: "applying-passes",
      service: "ApplyingPasses",
    },
  ];
  return (
    <div className="space-y-5">
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <p className="text-4xl font-ebGaramond">Our Services</p>
      </div>
      <div className="bg-white rounded-md overflow-hidden">
        {list?.map((element, index) => {
          return <Services element={element} />;
        })}
      </div>
    </div>
  );
};

export default ServiceRequest;
