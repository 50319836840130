import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo, Update } from "../../assets/image";

const PasswordSuccessful = () => {
    const navigate = useNavigate();
  return (
    <>
      <div className="main">
        {/* {logingDataLoading && <RippleLoader />} */}
        <div className="main-logo ">
          <img src={Logo} alt="logo" className="w-full h-full object-cover" />
        </div>
        <div
          className='center-h'
        >
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
            <div className="relative top-5 left-1/4 w-fit h-fit">
                <img
                  src={Update}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="main-view ">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-2xl font-ebGaramond font-normal mb-1">
                    Password Successful Updated!
                  </h1>
                  <p className="text-sm opacity-65">Login now</p>
                </div>
                <button
                  type="submit"
                  className="w-full block uppercase text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                  onClick={()=>{
                    navigate("/login")
                  }}
                >
                  Go to login
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PasswordSuccessful;
