import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// Get Country Data
export const getCountryData = createAsyncThunk(
  "getCountryData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("get-countries");
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const addressSlice = createSlice({
  name: "addressData",
  initialState: initialStates,
  extraReducers: {
    // Get Country Data
    [getCountryData.pending]: (state) => {
      state.getCountryDataStatus.loading = true;
    },
    [getCountryData.fulfilled]: (state, action) => {
      state.getCountryDataStatus.loading = false;
      state.getCountryDataStatus.data = action.payload;
      state.getCountryDataStatus.error = false;
    },
    [getCountryData.rejected]: (state) => {
      state.getCountryDataStatus.loading = false;
      state.getCountryDataStatus.error = true;
    },
  },
});

const { reducer } = addressSlice;
export default reducer;
