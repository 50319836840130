import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InfoCircle } from "iconsax-react";
import AddDirector from "./AddDirector";
import AddShareholder from "./AddShareholder";
import CompanyContext from "../CompanyContext";
import {
  ArrowForward,
  Avatar,
  BackArrow,
  Info,
  Plus,
  Trash,
} from "../../../assets/svg/AllSvg";
import { Logo } from "../../../assets/index";
import {
  Badge,
  CheckBox,
  InformationTag,
  Input,
  Tooltip,
} from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import Swal from "sweetalert2";
import {
  DeleteDirectorShareholder,
  employmentSupport,
  getCorporateSecretary,
  getNomineeDirectorsDetails,
} from "../../../redux/companies/slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RippleLoader from "../../../commons/RippleLoader";
import { getOrganisationDetails } from "../../../redux/organisation/slice";

const NomineeDirector = ({
  company_id,
  shareholders,
  nominee_directors,
  nominee_director_durations,
  nominee_director_deposits,
}) => {
  const [showDirectorModel, setShowDirectorModel] = useState(false);
  const [showShareholderModel, setShowShareholderModel] = useState(false);
  const [directorsArray, setDirectorsArray] = useState([]);
  const [nomineeData, setNomineeData] = useState({});
  const [shareholderArray, setShareholderArray] = useState([]);
  const [directorsError, setDirectorsError] = useState("");
  const [shareholderError, setShareholderError] = useState("");
  const { prev, next } = useContext(CompanyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setShareholderArray([...shareholderArray, ...shareholders]);
    setDirectorsArray([...directorsArray, ...nominee_directors]);
  }, [shareholders, nominee_directors]);

  const handleDelete = (id, type) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "error",
      type: "warning",
      html: `You won't be able to revert this!`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((response) => {
      if (response.isConfirmed === true) {
        dispatch(DeleteDirectorShareholder(id)).then((response) => {
          if (response.type === "DeleteDirectorShareholder/fulfilled") {
            toast.success(response.payload.message);
            if (type === "shareholder") {
              const updatedItems = shareholderArray.filter(
                (item) => item.id !== id
              );
              setShareholderArray(updatedItems);
            } else if (type === "directors") {
              const updatedItems = directorsArray.filter(
                (item) => item.id !== id
              );
              setDirectorsArray(updatedItems);
            }
          }
        });
      }
    });
  };

  // Selector
  const { NomineeDirectorsDetails, loading, CorporateSecretaryDetails } =
    useSelector((store) => ({
      NomineeDirectorsDetails:
        store?.companiesData?.getNomineeDirectorsDetailsStatus?.data?.data,
      loading: store?.companiesData?.loading,
      CorporateSecretaryDetails:
        store?.companiesData?.getCorporateSecretaryStatus?.data?.data,
    }));

  const details = CorporateSecretaryDetails?.detail;

  useEffect(() => {
    dispatch(getNomineeDirectorsDetails());
    dispatch(getCorporateSecretary());
  }, []);

  const handleSubmit = () => {
    if (!directorsArray.length) {
      setDirectorsError(true);
      return;
    }
    if (!shareholderArray.length) {
      setShareholderError(true);
      return;
    }
    dispatch(
      employmentSupport({ id: company_id ?? 14, data: nomineeData })
    ).then((res) => {
      if (res.type === "employmentSupport/fulfilled") {
        dispatch(getOrganisationDetails(company_id));
        next();
      }
    });
  };

  useEffect(() => {
    if (directorsArray.length) {
      setDirectorsError(false);
    }
    if (shareholderArray.length) {
      setShareholderError(false);
    }
  }, [directorsArray, shareholderArray]);

  const deValue =
    nominee_director_deposits === 10
      ? "months_3"
      : nominee_director_deposits === 12
      ? "months_6"
      : nominee_director_deposits === 15
      ? "year_1"
      : "";

  return (
    <>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="space-y-5">
          <div className="flex space-x-4">
            <Info />
            <div className="space-y-3">
              <p>
                Singapore law mandates that all incorporated companies must have
                at least one local resident director and one shareholder.
              </p>
              <p>
                You can use your own Local Director (Singaporean Citizen, PR or
                Entrepass holder) or sign up for our Nominee Local Director
                service.
              </p>
              <button className="text-theme border-b border-theme">
                Read More
              </button>
            </div>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              nominee_directors: deValue,
              terms_conditions: nominee_director_durations ? true : false,
            }}
            validationSchema={Yup.object().shape({
              nominee_directors: Yup.string().required(
                "Please Select Nominee Directors"
              ),
              terms_conditions: Yup.boolean().required(
                "Please Select Terms Conditions"
              ),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
            }) => (
              <Form>
                <div className="space-y-5">
                  <div className="space-y-3 border-b border-secondary_grey pb-5">
                    <p className="text-lg">1. Nominee Directors</p>
                    <p>Duration for Nominee Director</p>
                    <div className="grid grid-cols-4 gap-5 bg-light_secondary_shades rounded-lg py-5 px-6">
                      <div className="border-r-2 pr-4">
                        <p className="text-xl font-ebGaramond font-semibold mb-2">
                          Nominee Directors
                        </p>
                        <p className="text-light_grey font-medium text-sm">
                          You can always change the duration later should the
                          need arise.
                        </p>
                      </div>
                      <div className="col-span-3">
                        <div className="grid grid-cols-3 gap-3 mb-3">
                          <div>
                            <label
                              htmlFor={"months_3"}
                              className="relative cursor-pointer"
                            >
                              <Input
                                type="radio"
                                id={"months_3"}
                                name="nominee_directors"
                                value={"months_3"}
                                className="sr-only peer"
                                checked={values.nominee_directors == "months_3"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={() => {
                                  setNomineeData({
                                    nominee_director_durations: 3,
                                    nominee_director_deposits:
                                      NomineeDirectorsDetails?.detail?.months_3,
                                  });
                                }}
                              />
                              <div className="card !rounded-xl bg-white border peer-checked:border-theme peer-checked:bg-light_theme">
                                <p className="text-sm mb-1">3 Months</p>
                                <p className="text-lg">
                                  ${NomineeDirectorsDetails?.detail?.months_3}
                                </p>
                              </div>
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor={"months_6"}
                              className="relative cursor-pointer"
                            >
                              <Input
                                type="radio"
                                id={"months_6"}
                                name="nominee_directors"
                                value={"months_6"}
                                className="sr-only peer"
                                checked={values.nominee_directors == "months_6"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={() => {
                                  setNomineeData({
                                    nominee_director_durations: 6,
                                    nominee_director_deposits:
                                      NomineeDirectorsDetails?.detail?.months_6,
                                  });
                                }}
                              />
                              <div className="card !rounded-xl bg-white border peer-checked:border-theme peer-checked:bg-light_theme">
                                <p className="text-sm mb-1">6 Months</p>
                                <p className="text-lg">
                                  ${NomineeDirectorsDetails?.detail?.months_6}
                                </p>
                              </div>
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor={"year_1"}
                              className="relative cursor-pointer"
                            >
                              <Input
                                type="radio"
                                id={"year_1"}
                                name="nominee_directors"
                                value={"year_1"}
                                className="sr-only peer"
                                checked={values.nominee_directors == "year_1"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={() => {
                                  setNomineeData({
                                    nominee_director_durations: 12,
                                    nominee_director_deposits:
                                      NomineeDirectorsDetails?.detail?.year_1,
                                  });
                                }}
                              />
                              <div className="card !rounded-xl bg-white border peer-checked:border-theme peer-checked:bg-light_theme">
                                <p className="text-sm mb-1">12 Months</p>
                                <p className="text-lg">
                                  ${NomineeDirectorsDetails?.detail?.year_1}
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                        {errors.nominee_directors &&
                        touched.nominee_directors ? (
                          <InputError errorTitle={errors.nominee_directors} />
                        ) : null}
                        <p className="text-light_grey font-semibold text-xs mb-5">
                          *With $3,000 security deposit. Deposit will be
                          refunded at the end of the term.
                        </p>
                        <div>
                          <CheckBox
                            idFrom={"terms_conditions"}
                            label={
                              <p className="flex items-center">
                                I agree with the terms & conditions
                                <Tooltip
                                  tooltipLabelClass={"w-96"}
                                  label={
                                    <>
                                      <InfoCircle className="text-theme ml-1" />
                                    </>
                                  }
                                  tooltipLabel={
                                    <>
                                      <div className="space-y-2">
                                        <ul>
                                          Pricing :
                                          {details?.tiered_pricing ? (
                                            Object.entries(
                                              details.tiered_pricing
                                            ).map(([key, value]) => (
                                              <li key={key}>
                                                {key.replace(/_/g, " ")} :{" "}
                                                {value}%
                                              </li>
                                            ))
                                          ) : (
                                            <li>No tiered pricing available</li>
                                          )}
                                        </ul>
                                      </div>
                                    </>
                                  }
                                />
                              </p>
                            }
                            name={"terms_conditions"}
                            value={values.terms_conditions}
                            checked={values.terms_conditions}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {errors.terms_conditions &&
                          touched.terms_conditions ? (
                            <InputError errorTitle={errors.terms_conditions} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3 border-b border-secondary_grey pb-5">
                    <p className="text-lg">2. Directors</p>
                    <p>
                      An email invitation will be sent to company members for
                      identity verification.
                    </p>
                    {directorsError && (
                      <InformationTag
                        text={
                          "At least 1 Operational Director (Non Nominee Director) must be appointed"
                        }
                        type={"error"}
                      />
                    )}
                    <div className="grid grid-cols-4 gap-5 bg-light_secondary_shades rounded-lg py-5 px-6">
                      <div className="border-r-2 pr-4">
                        <p className="text-xl font-ebGaramond font-semibold mb-2">
                          Directors
                        </p>
                        <p className="text-light_grey font-medium text-sm">
                          At least 1 local resident director required
                        </p>
                      </div>
                      <div className="col-span-3 space-y-4">
                        <div
                          className={`space-y-4 max-h-80 overflow-y-auto mb-4 ${
                            directorsArray?.length && "p-3 pb-0"
                          }`}
                        >
                          {directorsArray?.map((element, index) => {
                            return (
                              <div
                                className={`card bg-white border flex justify-between items-center ${
                                  directorsArray.length === index - 1 && "mb-3"
                                }`}
                              >
                                <div className="flex items-center space-x-3">
                                  <Avatar width={45} height={45} />
                                  <div>
                                    <p className="text-lg font-medium">
                                      {element?.user?.full_name}
                                    </p>
                                    <p className="text-sm">
                                      {element?.user?.email}
                                    </p>
                                  </div>
                                </div>
                                {element?.main_signee ? (
                                  <Badge
                                    value={"Main Signee"}
                                    className={
                                      "border border-theme text-theme text-sm font-semibold px-4 !py-1"
                                    }
                                  />
                                ) : (
                                  <button
                                    className="p-3 ml-3"
                                    onClick={() => {
                                      handleDelete(element?.id, "directors");
                                    }}
                                  >
                                    <Trash width="16" height="20" />
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="p-3 pt-0">
                          <div className="card bg-white border flex space-x-3 items-center">
                            <img src={Logo} alt="logo" className="w-10 h-10" />
                            <p className="text-lg font-medium">
                              Ei BIZ Nominee Director
                            </p>
                          </div>
                        </div>
                        <button
                          className="btn-outline uppercase flex items-center ml-3"
                          onClick={() => {
                            setShowDirectorModel(true);
                          }}
                        >
                          <Plus className="fill-theme mr-2" />
                          Add director
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3 border-b border-secondary_grey pb-5">
                    <p className="text-lg">3. Shareholders</p>
                    <p>
                      An email invitation will be sent to company members for
                      identity verification.
                    </p>
                    {shareholderError && (
                      <InformationTag
                        text={"At least 1 Shareholder must be appointed"}
                        type={"error"}
                      />
                    )}
                    <div className="grid grid-cols-4 gap-5 bg-light_secondary_shades rounded-lg py-5 px-6">
                      <div className="border-r-2 pr-4">
                        <p className="text-xl font-ebGaramond font-semibold flex items-center mb-2">
                          Shareholders{" "}
                          <Tooltip
                            label={
                              <>
                                <InfoCircle className="text-theme ml-1" />
                              </>
                            }
                            tooltipLabel={
                              <>
                                <div className="whitespace-nowrap">
                                  Pricing:
                                  <li>
                                    1 Shareholder: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholder_1
                                    }
                                    /year
                                  </li>
                                  <li>
                                    2 Shareholders: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholders_2
                                    }
                                    /year
                                  </li>
                                  <li>
                                    3 - 5 Shareholders: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholders_3_5
                                    }
                                    /year
                                  </li>
                                  <li>
                                    6 - 9 Shareholders: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholders_6_9
                                    }
                                    /year
                                  </li>
                                  <li>
                                    10 - 20 Shareholders: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholders_10_20
                                    }
                                    /year
                                  </li>
                                  <li>
                                    21- 30 Shareholders: $
                                    {
                                      CorporateSecretaryDetails?.detail
                                        ?.tiered_pricing?.shareholders_21_30
                                    }
                                    /year
                                  </li>
                                  <li>{`>30 Shareholders: $${CorporateSecretaryDetails?.detail?.tiered_pricing?.greater_than_30_shareholders}/year`}</li>
                                </div>
                              </>
                            }
                          />
                        </p>
                        <p className="text-light_grey font-medium text-sm">
                          Starting at SGD 300 per year for corporate secretary
                          services
                        </p>
                      </div>
                      <div className="col-span-3 space-y-4">
                        <div
                          className={`space-y-4 max-h-80 overflow-y-auto mb-4 ${
                            shareholderArray?.length && "p-3"
                          }`}
                        >
                          {shareholderArray?.map((element) => {
                            return (
                              <div className="card bg-white border flex justify-between items-center">
                                <div className="flex items-center space-x-3">
                                  <Avatar width={45} height={45} />
                                  <div>
                                    <p className="text-lg font-medium">
                                      {element?.user?.full_name}
                                    </p>
                                    <p className="text-sm">
                                      {element?.user?.email}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  className="p-3"
                                  onClick={() => {
                                    handleDelete(element?.id, "shareholder");
                                  }}
                                >
                                  <Trash width="16" height="20" />
                                </button>
                              </div>
                            );
                          })}
                        </div>
                        <button
                          className="btn-outline uppercase flex items-center ml-3"
                          onClick={() => {
                            setShowShareholderModel(true);
                          }}
                        >
                          <Plus className="fill-theme mr-2" />
                          Add shareholder
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-4 pt-3">
                    <button
                      type="button"
                      className="btn-outline flex items-center space-x-3 uppercase"
                      onClick={() => prev()}
                    >
                      <BackArrow className="fill-theme" fillOpacity={1} />
                      <span>Back</span>
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-theme flex items-center space-x-3 uppercase"
                    >
                      <span>save & proceed on</span> <ArrowForward />
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {showDirectorModel && (
            <AddDirector
              setShowDirectorModel={setShowDirectorModel}
              showDirectorModel={showDirectorModel}
              company_id={company_id}
              setDirectorsArray={setDirectorsArray}
              directorsArray={directorsArray}
              nominee={true}
            />
          )}
          {showShareholderModel && (
            <AddShareholder
              setShowShareholderModel={setShowShareholderModel}
              shareholderArray={shareholderArray}
              setShareholderArray={setShareholderArray}
              company_id={company_id}
            />
          )}
        </div>
      )}
    </>
  );
};

export default NomineeDirector;
