import React, { useContext, useState, useEffect } from "react";
import { down, cart, up, goBack } from "../../assets/image";
import { useNavigate } from "react-router-dom";
import CompanyContext from "./CompanyContext";
import { employmentSupport, paymentSummary } from "../../redux/companies/slice";
import { useDispatch, useSelector } from "react-redux";
import { Plus } from "../../assets/svg/AllSvg";
import { getOrganisationDetails } from "../../redux/organisation/slice";

const Payment = ({ handleStepClick, companyId, no_of_locs, no_of_eps }) => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const [epCount, setEpCount] = useState(1);
  const [locCount, setLocCount] = useState(1);
  const { prev, next } = useContext(CompanyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentSummary(companyId));
  }, [dispatch]);

  const { paymentSummaryData } = useSelector((store) => ({
    paymentSummaryData: store?.companiesData?.paymentSummaryStatus?.data?.data,
  }));

  const handleFormSubmit = () => {
    if (!(epCount == 0) || !(locCount == 0)) {
      dispatch(
        employmentSupport({
          id: companyId,
          data: {
            employment_support: true,
            no_of_eps: epCount,
            no_of_locs: locCount,
          },
        })
      ).then((res) => {
        if (res.type === "employmentSupport/fulfilled") {
          dispatch(getOrganisationDetails(companyId));
          navigate("/payment-last", {
            state: {
              companyId: companyId,
            },
          });
          if (handleStepClick) {
            next();
          }
        }
      });
    } else {
      navigate("/payment-last", {
        state: {
          companyId: companyId,
        },
      });
      if (handleStepClick) {
        dispatch(getOrganisationDetails(companyId));
        next();
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setEpCount(no_of_eps);
    setLocCount(no_of_locs);
  }, [no_of_locs, no_of_eps]);
  const data = Array.from({ length: 30 }, (_, i) => ({
    name: `${i + 1} Shareholder`,
  }));
  const handleEpIncrement = () => {
    setEpCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };
  const handleEpDecrement = () => {
    setEpCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };
  const handleLocIncrement = () => {
    setLocCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };
  const handleLocDecrement = () => {
    setLocCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };
  useEffect(() => {
    if (paymentSummaryData?.no_of_eps) {
      setEpCount(paymentSummaryData?.no_of_eps);
    }
    if (paymentSummaryData?.no_of_locs) {
      setLocCount(paymentSummaryData?.no_of_locs);
    }
  }, [paymentSummaryData]);
  const employmentPass = epCount * paymentSummaryData?.employment_pass;
  const dependantPassLoc = locCount * paymentSummaryData?.dependant_pass_loc;
  const total =
    paymentSummaryData?.incorporation_fees +
    paymentSummaryData?.govt_fees +
    employmentPass +
    dependantPassLoc +
    paymentSummaryData?.corporate_secretary_fees +
    paymentSummaryData?.annual_filing_fees;
  return (
    <>
      <div>
        <div className="card border p-4 shadow-lg bg-white rounded-lg">
          <button
            className={
              "bg-white hover:bg-grey-700 text-theme font-bold rounded flex"
            }
            onClick={() => prev()}
          >
            <img className="w-5 h-5" src={goBack} />
            <span className="ml-2 font-poppins font-normal text-sm">BACK</span>
          </button>
          <div className="p-2">
            <p className="font-ebGaramond font-normal text-lg">
              Base on what we had gathered
            </p>
            <p className="font-ebGaramond font-normal text-xl">
              Here’s the summary and breakdown
            </p>
          </div>
          <p className="border" />

          <div className="mt-2 w-full flex justify-between">
            <div className="bg-bgPrimary p-3 rounded-md flex justify-between w-2/3">
              <p className="font-poppins font-normal text-sm">Total Payable:</p>
              <p className="font-poppins font-normal text-sm">
                $
                {!(epCount == 0) || !(locCount == 0)
                  ? total
                  : paymentSummaryData?.total}
              </p>
            </div>
            <div className="w-1/4 h-5">
              <button
                className="border rounded-3xl p-2 flex items-center shadow-lg bg-gray-200 w-32"
                onClick={toggleExpand}
              >
                <img
                  className="mr-1"
                  src={isExpanded ? up : down}
                  alt="Arrow"
                />
                <p className="font-poppins font-normal text-sm">
                  {isExpanded ? "View Less" : "View More"}
                </p>
              </button>
            </div>
          </div>
          <p className="border mt-3" />

          <div className="mt-2">
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
              <p className="font-ebGaramond font-normal text-xl">
                1. Company Incorporation
              </p>
              <div>
                <p className="font-poppins font-light text-xs">one time fees</p>
                <p className="font-poppins font-normal text-xs">
                  {" "}
                  ${paymentSummaryData?.incorporation_fees}
                </p>
              </div>
            </div>
            {isExpanded && (
              <>
                <button
                  className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                  onClick={toggleExpand}
                >
                  <p className="font-poppins font-light text-base">
                    i. Registration filing and name application
                  </p>
                  <img
                    className="mr-1"
                    src={isExpanded ? up : down}
                    alt="Arrow"
                  />
                </button>
                <div className="p-2">
                  <ul className="text-xs font-normal list-inside text-themeBlue list-disc">
                    <li>Checking and reserving company name</li>
                    <li>Preparing all registration forms</li>
                    <li>
                      Preparing your company constitution (memorandum & articles
                      of the company)
                    </li>
                    <li>
                      Compiling compliance corporate kit, including share
                      certificates, registers, and minutes book
                    </li>
                    <li>Assisting with opening a bank/business account</li>
                  </ul>
                </div>
              </>
            )}
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2">
              <p className="ont-poppins font-light text-base">
                ii. Government Registration and Name Application Fees
              </p>
              <div>
                <p className="font-poppins font-light text-xs">one time fees</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.govt_fees}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
              <p className="font-ebGaramond font-normal text-xl">
                2. Corporate Secretary Package
              </p>
              <div>
                <p className="font-poppins font-light text-xs">Per Year</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.corporate_secretary_fees}
                </p>
              </div>
            </div>
            {isExpanded && (
              <>
                <button
                  className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                  onClick={toggleExpand}
                >
                  <p className="font-poppins font-light text-base">
                    i. This service offers access to common company secretary
                    services
                  </p>
                  <img
                    className="mr-1"
                    src={isExpanded ? up : down}
                    alt="Arrow"
                  />
                </button>
                <div className="p-2">
                  <ul className="text-xs font-normal list-inside text-themeBlue list-disc">
                    <li>Preparation & holding of AGM </li>
                    <li>Preparation & filing of Annual Return</li>
                    <li>Declaration and distribution of dividends</li>
                    <li>Many more ACRA transactions at no extra cost.</li>
                  </ul>
                  <div className="w-full pt-2">
                    <div className="relative border field">
                      <label
                        htmlFor="name"
                        className={
                          "label text-sm peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-light_grey"
                        }
                      >
                        Shareholder
                      </label>
                      <select
                        id="data"
                        name="data"
                        defaultValue=""
                        className="auth-input error-forms border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      >
                        <option value="" disabled>
                          {paymentSummaryData?.shareholders_count} Shareholder
                          {paymentSummaryData?.shareholders_count > 1
                            ? "s"
                            : ""}
                        </option>
                        {data.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="font-poppins font-light text-xs">
                      Psst* Make sure you had selected the correct numbers of
                      Shareholder
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2">
              <p className="ont-poppins font-light text-base">
                ii. Annual Filing Fee
              </p>
              <div>
                <p className="font-poppins font-light text-xs">Per Year</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.annual_filing_fees}
                </p>
              </div>
            </div>
          </div>

          <p className="border my-3" />
          {!epCount == 0 && (
            <div className="space-y-3 mb-4">
              <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
                <p className="font-ebGaramond font-normal text-xl">
                  3. Employment Pass Support
                </p>
                <div className="text-right">
                  <p className="font-poppins font-light text-xs">
                    one time fees
                  </p>
                  <p className="font-poppins font-normal text-xs">
                    ${epCount * paymentSummaryData?.employment_pass}
                  </p>
                </div>
              </div>
              {isExpanded && (
                <>
                  <button
                    className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                    onClick={toggleExpand}
                  >
                    <p className="font-poppins font-light text-base">
                      i. Employment Pass (EP)
                    </p>
                    <img
                      className="mr-1"
                      src={isExpanded ? up : down}
                      alt="Arrow"
                    />
                  </button>
                  <div className="p-2 flex justify-between">
                    <p className="text-sm font-normal list-inside max-w-80">
                      EP allows an employer to hire a foreigner in Singapore. It
                      is the most common type of work visa
                    </p>
                    <div>
                      <label
                        htmlFor="quantity-input"
                        className="block mb-1 text-sm font-normal text-gray-900"
                      >
                        Number of EPs
                      </label>
                      <div>
                        <div className="flex items-center max-w-[8rem]">
                          <button
                            type="button"
                            id="decrement-button"
                            onClick={handleEpDecrement} // Change this line
                            className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                          >
                            <svg
                              className="w-3 h-3 text-theme"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 2"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h16"
                              />
                            </svg>
                          </button>
                          <input
                            type="text"
                            id="quantity-input"
                            aria-describedby="helper-text-explanation"
                            className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                            value={epCount}
                            readOnly
                          />
                          <button
                            type="button"
                            id="increment-button"
                            onClick={handleEpIncrement} // Change this line
                            className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                          >
                            <Plus className="w-3 h-3 fill-theme" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {!locCount == 0 && (
            <div className="space-y-3 mb-4">
              <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
                <p className="font-ebGaramond font-normal text-xl">
                  4. Dependent Pass Support
                </p>
                <div className="text-right">
                  <p className="font-poppins font-light text-xs">
                    one time fees
                  </p>
                  <p className="font-poppins font-normal text-xs">
                    ${locCount * paymentSummaryData?.dependant_pass_loc}
                  </p>
                </div>
              </div>
              {isExpanded && (
                <>
                  <button
                    className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                    onClick={toggleExpand}
                  >
                    <p className="font-poppins font-light text-base">
                      ii. Dependent Pass (DP)
                    </p>
                    <img
                      className="mr-1"
                      src={isExpanded ? up : down}
                      alt="Arrow"
                    />
                  </button>
                  <div className="p-2 flex justify-between">
                    <p className="text-sm font-normal list-inside max-w-80">
                      DP allows an employer to hire a foreigner in Singapore. It
                      is the most common type of work visa
                    </p>
                    <div>
                      <label
                        htmlFor="quantity-input"
                        className="block mb-1 text-sm font-normal text-gray-900"
                      >
                        Number of LOC’s
                      </label>
                      <div>
                        <div className="flex items-center max-w-[8rem]">
                          <button
                            type="button"
                            id="decrement-button"
                            onClick={handleLocDecrement} // Change this line
                            className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                          >
                            <svg
                              className="w-3 h-3 text-theme"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 2"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h16"
                              />
                            </svg>
                          </button>
                          <input
                            type="text"
                            id="quantity-input"
                            aria-describedby="helper-text-explanation"
                            className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                            value={locCount}
                            readOnly
                          />
                          <button
                            type="button"
                            id="increment-button"
                            onClick={handleLocIncrement} // Change this line
                            className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                          >
                            <svg
                              className="w-3 h-3 text-theme"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 18 18"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 1v16M1 9h16"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {/* button */}
        <div className="card border p-4 shadow-lg bg-white mt-3 rounded-lg flex justify-between">
          <button
            className={
              "bg-theme hover:bg-grey-700 text-white font-bold py-2 px-4 rounded flex"
            }
            onClick={handleFormSubmit}
          >
            <span className="mr-2 font-poppins font-normal text-sm">
              Continue to check out
            </span>
            <img className="w-5 h-5" src={cart} />
          </button>
          <div>
            <p className="font-poppins font-light text-xs">Total:</p>
            <p className="font-poppins font-normal text-sm">
              $
              {!(epCount == 0) || !(locCount == 0)
                ? total
                : paymentSummaryData?.total}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
