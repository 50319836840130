import React, { useState } from "react";
import { User } from "iconsax-react";
import { Logo } from "../../assets/image";
import FileUploadChoose from "../../commons/FileUploadChoose";
import ChatModal from "./ChatImg";

const ChatList = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: "Message content goes here", sender: "support" },
    { id: 2, text: "Message content goes here", sender: "user" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [showChat, setShowChat] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleSend = () => {
    if (newMessage.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: prevMessages.length + 1, text: newMessage, sender: "user" },
      ]);
      setNewMessage("");
    }
  };

  const handleUploadImg = () => {
    setShowUpload(true);
  };

  const handleFileChange = (files) => {
    setSelectedFiles(Array.from(files));
  };

  const removeSelectedFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = () => {
    setSelectedFiles([]);
    setMessages((prevMessages) => [
      ...prevMessages,
      ...selectedFiles.map((file, index) => ({
        id: prevMessages.length + index + 1,
        text: "",
        sender: "user",
        file: URL.createObjectURL(file),
        type: "image",
      })),
    ]);

    setShowUpload(false);
    setShowChat(true);
  };

  const openImageModal = (ind) => {
    setSelectedImageIndex(ind);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  return (
    <>
      {showChat && (
        <div className="fixed bottom-28 right-10 w-96 h-[70vh] bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col p-4">
          <div className="bg-bgBlue text-white p-4 rounded-t-2xl flex items-center">
            <img src={Logo} alt="logo" className="w-14 h-14" />
            <div className="ml-5">
              <h2 className="text-lg font-bold text-black">
                Ei Biz Live Chat Support
              </h2>
              <p className="text-black">Back Online at 9:00am</p>
            </div>
          </div>
          <div className="flex-1 p-4 overflow-y-auto">
            {messages.map((message, ind) => (
              <div
                key={message.id}
                className={`mb-4 flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {message.sender !== "user" && (
                  <div className="flex-shrink-0 mr-2 mt-1">
                    <div className="bg-gray-400 w-8 h-8 rounded-full flex items-center justify-center text-white">
                      <User className="h-5 w-5 fill-white text-white" />
                    </div>
                  </div>
                )}
                <div
                  className={`max-w-xs p-2 rounded-lg ${
                    message.sender === "user"
                      ? "bg-lightGreen text-white"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  {message.type === "image" ? (
                    <img
                      src={message.file}
                      alt="Uploaded"
                      className="w-24 h-24 object-cover rounded-lg border-2 border-gray-300"
                      onClick={() => openImageModal(ind)}
                    />
                  ) : (
                    message.text
                  )}
                </div>
                {message.sender === "user" && (
                  <div className="flex-shrink-0 ml-2 mt-1">
                    <div className="bg-bgGreen w-8 h-8 rounded-full flex items-center justify-center text-white">
                      <User className="h-5 w-5 fill-white text-white" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button className="inline-flex justify-center items-center mx-5 my-2 p-1 cursor-pointer text-black hover:bg-gray-100 dark:hover:bg-gray-200 border border-black rounded-lg">
            <p className="mr-2 font-poppins text-sm font-medium">CHAT WITH LIVE SUPPORT</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 12.22C21 6.73 16.74 3 12 3C7.31 3 3 6.65 3 12.28C2.4 12.62 2 13.26 2 14V16C2 17.1 2.9 18 4 18H5V11.9C5 8.03 8.13 4.9 12 4.9C15.87 4.9 19 8.03 19 11.9V19H11V21H19C20.1 21 21 20.1 21 19V17.78C21.59 17.47 22 16.86 22 16.14V13.84C22 13.14 21.59 12.53 21 12.22Z"
                fill="black"
                fillOpacity="0.54"
              />
              <path
                d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
                fill="black"
                fillOpacity="0.54"
              />
              <path
                d="M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z"
                fill="black"
                fillOpacity="0.54"
              />
              <path
                d="M18.0024 11.03C17.5224 8.18 15.0424 6 12.0524 6C9.02241 6 5.76241 8.51 6.02241 12.45C8.49241 11.44 10.3524 9.24 10.8824 6.56C12.1924 9.19 14.8824 11 18.0024 11.03Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          </button>
          <div className="border-t border-gray-300">
            <form>
              <label htmlFor="chat" className="sr-only">
                Your message
              </label>
              <div className="flex items-center px-3 py-2 rounded-lg">
                <button
                  type="button"
                  className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-200"
                  onClick={handleUploadImg}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.6">
                      <path
                        d="M18 13V20H4V6H9.02C9.07 5.29 9.24 4.62 9.5 4H4C2.9 4 2 4.9 2 6V20C2 21.1 2.9 22 4 22H18C19.1 22 20 21.1 20 20V15L18 13ZM16.5 18H5.5L8.25 14.47L10.21 16.83L12.96 13.29L16.5 18ZM19.3 8.89C19.74 8.19 20 7.38 20 6.5C20 4.01 17.99 2 15.5 2C13.01 2 11 4.01 11 6.5C11 8.99 13.01 11 15.49 11C16.37 11 17.19 10.74 17.88 10.3L21 13.42L22.42 12L19.3 8.89ZM15.5 9C14.12 9 13 7.88 13 6.5C13 5.12 14.12 4 15.5 4C16.88 4 18 5.12 18 6.5C18 7.88 16.88 9 15.5 9Z"
                        fill="black"
                        fillOpacity="0.54"
                      />
                    </g>
                  </svg>
                  <span className="sr-only">Upload image</span>
                </button>
                <input
                  type="text"
                  className="flex-1 p-2 border border-gray-300 rounded-lg"
                  placeholder="Type a message..."
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                />
                <button
                  className="bg-white text-theme p-2 rounded-r-lg flex items-center justify-center"
                  onClick={handleSend}
                >
                  <svg
                    className="w-5 h-5 rotate-90 rtl:-rotate-90"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                  </svg>
                  <span className="sr-only">Send message</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showUpload && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[70vh]">
            <h2 className="text-base font-normal mb-4">Upload File(s)</h2>
            <div className="mt-4">
              {selectedFiles.length > 0 && (
                <div className="mt-4">
                  <div className="flex space-x-4 mt-2 mb-2">
                    {selectedFiles?.map((file, index) => (
                      <div key={index} className="flex-shrink-0">
                        <button
                          className="relative top-2 left-10"
                          onClick={() => removeSelectedFile(index)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                              fill="black"
                              fillOpacity="0.54"
                            />
                          </svg>
                        </button>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Selected file ${index + 1}`}
                          className="w-14 h-20 object-cover rounded-lg border-2 border-gray-300"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <FileUploadChoose
                handleFileChange={(e) => handleFileChange(e.target.files)}
              />
              <div className="flex justify-end">
                <button
                  className="mt-4 border-theme border text-theme px-4 py-2 rounded-lg mr-2"
                  onClick={() => {
                    setShowUpload(false);
                    setShowChat(true);
                  }}
                >
                  Close
                </button>
                <button
                  className={`mt-4 px-4 py-2 rounded-lg ${
                    selectedFiles.length > 0
                      ? "bg-theme text-white"
                      : "bg-gray-200 text-black"
                  }`}
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedImageIndex !== null && (
        <ChatModal
          imageUrl={messages[selectedImageIndex].file}
          onClose={closeImageModal}
        />
      )}
    </>
  );
};

export default ChatList;
