import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BackButton,
  InputWithLabel,
  TextareaWithLabel,
} from "../../../components/micro";
import { headChat } from "../../../assets/image";
import ReactSelect from "react-select";
import { Label } from "@headlessui/react";
import { Info } from "../../../assets/svg/AllSvg";

const ChangeBusinessActivity = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-5">
        <BackButton
          onClick={() => {
            navigate("/service-request");
          }}
        />
        <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
          <img src={headChat} alt="img" />
          <div className="space-y-2 font-ebGaramond">
            <p className="text-xl">We see that you will need a request on:</p>
            <p className="text-3xl">Change of Business Activity </p>
          </div>
        </div>
        <div className="border-b-2 border-secondary_grey pb-5">
          <p className="text-2xl font-ebGaramond">
            Once the request is opened. You can easily manage it over at
            “On-Going” service page.
          </p>
        </div>
        <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
          <p className="text-2xl font-ebGaramond">
            Let us guide u though the process:
          </p>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>1.</span>
            <p>Check if the New Company Name is available</p>
          </div>
          <p className="text-lg">Re-Classify your company</p>
          <p className="text-sm">
            Singapore Standard Industrial Classification (SSIC) code for your
            business
          </p>
          <button className="text-theme border-b border-theme">
            Quick Guide
          </button>
          <ReactSelect
            className={"p-0"}
            id="primary_activity"
            placeholder="SSIC - Company Primary Activity"
            options={[{ value: "value", label: "value" }]}
            isLoading={false}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
          <TextareaWithLabel
            idFromName={"primary_activity_description"}
            label={"Primary Activity Description"}
            className={"border"}
          />
          <ReactSelect
            className={"p-0"}
            id="primary_activity"
            placeholder="SSIC - Company Primary Activity"
            options={[{ value: "value", label: "value" }]}
            isLoading={false}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
          <TextareaWithLabel
            idFromName={"primary_activity_description"}
            label={"Primary Activity Description"}
            className={"border"}
          />
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>2.</span>
            <p>We will prepare relevant resolution</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>3.</span>
            <p>Obtain signed resolution</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>4.</span>
            <p>
              We will lodge the changes to ACRA for the change of Company Name
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
          <p className="font-semibold flex items-center">
            <Info className="mr-3" />
            Total Payable :
          </p>
          <div className="flex items-center space-x-7">
            <p className="text-xs text-gray-400">3/3 used</p>
            <div>
              <p className="text-xs">1/3 used</p>
              <p>Free</p>
            </div>
          </div>
        </div>
        <div className="box !rounded-xl flex items-center justify-between">
          <button
            className={`btn-theme uppercase`}
            onClick={() => {
              navigate("/approver");
            }}
          >
            Proceed with request
          </button>
          <div>
            <p className="text-xs">Total:</p>
            <p>$100</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeBusinessActivity;
