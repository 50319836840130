import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Provider } from "./layoutContext";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { getProfile } from "../redux/auth/slice";
import { getToken, messaging } from "../commons/Firebase";

export const AdminContext = createContext({});

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [windowScreenWidth, setwindowScreenWidth] = useState(window.innerWidth);

  const getAccessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    if (getAccessToken) {
      dispatch(getProfile({ navigate }));
    }
  }, [dispatch, getAccessToken]);

  useEffect(() => {
    function handleResize() {
      setwindowScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    windowScreenWidth < 767 && setIsAsideOpen(false);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowScreenWidth]);

  return (
    <div className="flex bg-lightyellow">
      {getAccessToken ? (
        <Provider value={{ isAsideOpen, setIsAsideOpen }}>
          <div
            className={`h-screen flex flex-col min-w-0 flex-1 overflow-y-hidden overflow-x-hidden`}
          >
            <Header />
            <div className="flex">
              <Sidebar />
              <div className="h-[calc(100vh-4rem)] overflow-y-auto bg-lightBackgroundDefault w-full px-6 py-8">
                <Outlet />
              </div>
            </div>
          </div>
        </Provider>
      ) : (
        <>
          <Navigate to={"/login"} replace />
        </>
      )}
    </div>
  );
};

export default MainLayout;
