import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`upload`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState: initialStates,
  extraReducers: {
    // Upload File
    [uploadFile.pending]: (state) => {
      state.loading = true;
    },
    [uploadFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadStatus.data = action.payload;
      state.error = false;
    },
    [uploadFile.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

const { reducer } = uploadFileSlice;
export default reducer;
