import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// Create Ticket
export const createTicket = createAsyncThunk(
  "createTicket",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`tickets`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// get Tickets List
export const getTicketsList = createAsyncThunk(
  "getTicketsList",
  async (
    { search, priority, period, status, pageLimit, pageCount },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `tickets?search=${search}&priority=${priority}&status=${status}&period=${period}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// get Tickets
export const getTickets = createAsyncThunk(
  "getTickets",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`tickets/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Replay Ticket
export const replayTicket = createAsyncThunk(
  "replayTicket",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`tickets/${id}`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Ticket
export const deleteTicket = createAsyncThunk(
  "deleteTicket",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`tickets/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialStates,
  extraReducers: {
    // Upload File
    [createTicket.pending]: (state) => {
      state.loading = true;
    },
    [createTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.createTicketStatus.data = action.payload;
      state.error = false;
    },
    [createTicket.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Tickets List
    [getTicketsList.pending]: (state) => {
      state.loading = true;
    },
    [getTicketsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.getTicketsListStatus.data = action.payload;
      state.error = false;
    },
    [getTicketsList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Tickets
    [getTickets.pending]: (state) => {
      state.getTicketsStatus.loading = true;
    },
    [getTickets.fulfilled]: (state, action) => {
      state.getTicketsStatus.loading = false;
      state.getTicketsStatus.data = action.payload;
      state.error = false;
    },
    [getTickets.rejected]: (state) => {
      state.getTicketsStatus.loading = false;
      state.error = true;
    },
    // Replay Ticket
    [replayTicket.pending]: (state) => {
      state.loading = true;
    },
    [replayTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.replayTicketStatus.data = action.payload;
      state.error = false;
    },
    [replayTicket.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Delete Ticket
    [deleteTicket.pending]: (state) => {
      state.loading = true;
    },
    [deleteTicket.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteTicketStatus.data = action.payload;
      state.error = false;
    },
    [deleteTicket.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

const { reducer } = ticketSlice;
export default reducer;
