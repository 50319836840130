import axios from "axios";
import cookie from "react-cookies";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASEURL;

export const AxiosApi = axios.create({
  baseURL,
});

AxiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      cookie.remove("login-token", { path: "/" });
      sessionStorage.removeItem("accessToken");
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

if (sessionStorage.getItem("accessToken")) {
  AxiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("accessToken")}`;
}
